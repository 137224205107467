/* eslint-disable no-unused-vars */
import React, {useEffect} from "react";
import Layout from "../../components/Layout";
import AboutImage from "../../assets/about.jpg";
import Icons from "../../components/Icons";
import { motion, useAnimation } from "framer-motion";
import {
  opacityAnimation,
  staggerChildrenAnimation,
} from "../../utils/animations";
import { useInView } from "react-intersection-observer";
import useHandleAnimation from "../../hooks/useHandleAnimation";
import { useLocation } from 'react-router-dom';

const About = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const animate = useHandleAnimation(inView, controls);

  const { pathname } = useLocation();
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[pathname])

  return (
    <Layout>
      <motion.section
        variants={staggerChildrenAnimation}
        animate={controls}
        className="container my-8 flex flex-col space-y-8"
        ref={ref}
        initial="hidden"
      >
        <div>
          <motion.img
            className="w-full h-[40vh] object-cover object-center rounded-lg mb-4"
            alt="about-banner"
            src={AboutImage}
            variants={opacityAnimation}
          />
          <motion.h2 className="text-h2" variants={opacityAnimation}>
            ЗА МЕН
          </motion.h2>
          <motion.p variants={opacityAnimation} className="text-lg">
            Аз вярвам че всеки рожденик заслужава да бъде специален. Стремя се
            да превърна вашия специлен повод в незабравимо преживяване. С моето
            прецизно планиране, внимание към детайлите се ангажирам да
            предоставям партита които оставят трайно впечатление. Отделям време
            да ви изслушам и да разбера вашите желания и очаквания, като
            гарантирам, че всеки елемент от събитието ще се отрази на
            <br />
            <br />
            Завършила съм актьорско майсторство в театрален колеж "Любен Гройс".
            Работя само с най-добрите в сферата, за да осигуря най-високо
            качество във всеки детайл на събитието. Вярвам, че нито един детайл
            не е твърде малък, тъй като се стремя да създам атмосфера, която да
            завладее гостите ви още от първия момент.
            <br />
            <br />
            Полагам допълнителни усилия, за да включа уникални и неочаквани
            елементи, които ще грабнат гостите ви и ще направят събитието ви
            наистина незабравимо.Независимо дали става въпрос за изненада с
            дейности или детайли аз създавам атмосфера, изпълнена с радост и
            смях.
          </motion.p>
        </div>
      </motion.section>
    </Layout>
  );
};

export default About;
