import React from "react";
import { cn } from "../utils/cn";

const defaultClasses =
  "inline-flex items-center justify-center rounded-[4px] font-medium transition-colors focus:outline-none disabled:pointer-events-none";

const sizes = {
  default: "px-8 py-3 md:py-4 md:px-12",
  small: "",
  large: "",
};

const variants = {
  default: "bg-primary hover:bg-primaryDark text-white font-semibold",
  primary: "",
  secondary: "",
};

const Button = ({
  children,
  size = "default",
  variant = "default",
  className,
  onClick,
  disabled,
}) => {
  const classes = cn(defaultClasses, sizes[size], variants[variant], className);

  return (
    <button onClick={onClick} disabled={disabled} className={classes}>
      {children}
    </button>
  );
};

export default Button;
