import React, {useEffect} from "react";
import Layout from "./components/Layout";
import Hero from "./pages/Home/Hero";
import Introduction from "./pages/Home/Introduction";
import { useLocation } from 'react-router-dom';

function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])

  return (
    <Layout>
      <Hero />
      <Introduction />
    </Layout>
  );
}

export default App;
