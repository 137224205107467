const isMobile = window.innerWidth < 768;

export const staggerChildrenAnimation = !isMobile
  ? {
      hidden: { opacity: 0 },
      visible: {
        opacity: 1,
        transition: {
          staggerChildren: 0.33,
        },
      },
    }
  : null;

export const translateYAnimation = !isMobile
  ? {
      hidden: { opacity: 0, translateY: "60px" },
      visible: {
        opacity: 1,
        translateY: "0px",
        transition: { duration: 0.8 },
      },
    }
  : null;

export const opacityAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 0.8 },
  },
};
