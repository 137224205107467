import { Menu, X, Facebook, Phone, Mail, Map } from "lucide-react";

const Icons = {
  Menu,
  X,
  Facebook,
  Phone,
  Mail,
  Map
};

export default Icons;
