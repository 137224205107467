import { useEffect } from "react";

const useHandleAnimation = (inView, controls) => {
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
};

export default useHandleAnimation;
