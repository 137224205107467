import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../../components/Layout";
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Icons from "../../components/Icons";

const Contact = () => {
    const url = 'https://viksitriksi.com/mailer/send-mail.php';
    const [fromInput, setFromInput] = useState('');
    const [subjectInput, setSubjectInput] = useState('');
    const [messageInput, setMessageInput] = useState('');

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname])


    const handleFromChange = (event) => {
        setFromInput(event.target.value);
    }

    const handleSubjectChange = (event) => {
        setSubjectInput(event.target.value);
    }

    const handleMessageChange = (event) => {
        setMessageInput(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const body = JSON.stringify({ from: fromInput, subject: subjectInput, message: messageInput });
        console.log(body)
        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        };

        axios.post(url, body, config)
            .then((response) => {
                toast.success(response.data.message);
                setFromInput('');
                setSubjectInput('');
                setMessageInput('');
            })
            .catch((error) => {
                toast.error("Неусепшно изпращане!");
                setFromInput('');
                setSubjectInput('');
                setMessageInput('');
            });
    }
    return (
        <Layout>
            <section className="bg-white" id="contact">
                <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20">
                    <div className="mb-4">
                        <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
                            <h2
                                className="font-heading mb-4 font-bold tracking-tight text-gray-900 dark:text-white text-3xl sm:text-5xl">
                                Свържете се с мен
                            </h2>
                        </div>
                    </div>
                    <div className="flex items-stretch justify-center">
                        <div className="grid md:grid-cols-2">
                            <div className="h-full pr-6">
                                <p className="mt-3 mb-12 text-lg text-gray-600 dark:text-slate-400">
                                    Добре дошли на страницата за контакт! Ако имате въпроси, идеи или ако искате да направим нещо уникално заедно, не се колебайте да се свържете с мен.
                                </p>
                                <ul className="mb-6 md:mb-0">
                                    <li className="flex">
                                        <div className="flex h-10 w-10 justify-center rounded text-primary">
                                            <a href="tel:+359899019997" className="text-xl mx-2"><Icons.Phone /></a>                                        </div>
                                        <div className="ml-4 mb-4">
                                            <p className="text-gray-600 dark:text-slate-400">Телефон: 089 901 9997</p>
                                        </div>
                                    </li>
                                    <br />
                                    <li className="flex">
                                        <div className="flex h-10 w-10 justify-center rounded text-primary">
                                            <a href="mailto:viktoriya.2000@abv.bg" className="text-xl mx-2"><Icons.Mail /></a>
                                        </div>
                                        <div className="ml-4 mb-4">
                                            <p className="text-gray-600 dark:text-slate-400">Емайл: viktoriya.2000@abv.bg</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="card h-fit max-w-6xl p-5 md:p-12" id="form">
                                <form onSubmit={handleSubmit} id="contactForm">
                                    <div className="mb-6">
                                        <div className="mx-0 mb-1 sm:mb-4">
                                            <div className="mx-0 mb-1 sm:mb-4">
                                                <label htmlFor="email" className="pb-1 uppercase">Вашият имейл</label>
                                                <input type="email" id="email" value={fromInput} onChange={handleFromChange} name="from" className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0" placeholder="name@flowbite.com" required />                                            </div>
                                            <div className="mx-0 mb-1 sm:mb-4">
                                                <label htmlFor="subject" className="pb-1 uppercase">Относно за
                                                </label>
                                                <input type="text" id="subject" value={subjectInput} onChange={handleSubjectChange} name="subject" className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0" placeholder="Кажете ми как мога да ви помогна." required />
                                            </div>
                                        </div>
                                        <div className="mx-0 mb-1 sm:mb-4">
                                            <label htmlFor="message" className="pb-1 uppercase">Вашето съобщение</label>
                                            <textarea required id="message" cols="30" rows="5" value={messageInput} onChange={handleMessageChange} name="message" className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0" placeholder="Оставете съобщение..."></textarea>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="w-full bg-primary hover:bg-primaryDark text-white px-6 py-3 font-xl rounded-md sm:mb-0">Изпрати</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Contact;