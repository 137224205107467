import React, { useState } from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";
import Icons from "./Icons";
import { Link } from "react-router-dom";

const links = [
  {
    href: "/",
    text: "НАЧАЛО",
  },
  {
    href: "/about",
    text: "ЗА МЕН",
  },
  {
    href: "/gallery",
    text: "ГАЛЕРИЯ",
  },
  {
    href: "/contact",
    text: "KОНТАКТИ",
  },
];

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <header className="border-b-primary/30 border-b py-4">
      <nav className="container flex justify-between items-center">
        <Link to="/">
          <Logo className="h-[33px] w-[185px]" />
        </Link>

        <ul className="hidden md:flex space-x-4">
          {links.map((data, i) => (
            <li
              key={`nav_link${i}`}
              className="hover:text-primaryDark text-primary transition-colors text-p active:text-indigo-600"
            >
              <Link to={data.href}>{data.text}</Link>
            </li>
          ))}
        </ul>
        <Icons.Menu
          onClick={() => setToggleMenu(!toggleMenu)}
          className="md:hidden"
          size="28px"
        />
      </nav>

      {toggleMenu && (
        <div className="bg-neutral fixed h-screen top-0  right-0 w-1/2">
          <Icons.X
            onClick={() => setToggleMenu(!toggleMenu)}
            className="text-white top-5 right-4 absolute"
            size="28px"
          />

          <ul className="flex flex-col space-y-4 items-center justify-center h-full">
            {links.map((data, i) => (
              <li key={`nav_link_mobile${i}`} className="text-darkGray text-p">
                <Link to={data.href}>{data.text}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </header>
  );
};

export default Navbar;
