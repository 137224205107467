import React from "react";
import Button from "../../components/Button";
const Hero = () => {
  return (
    <section className="bg-[url('./assets/hero.jpg')] w-full h-[60vh] bg-no-repeat bg-center bg-cover flex flex-col items-center justify-center space-y-4 md:space-y-8">
      <h2 className="max-w-[800px] text-h3 md:text-h2 md:leading-[40px] text-darkGray text-center font-bold">
        Трудно ви е да планирате перфектното парти за рождения ден на детето си?
      </h2>
      <a href="tel:+359899019997"><Button>ЗАПОЧНИ СЕГА</Button></a>
    </section>
  );
};

export default Hero;
