import dance from "../assets/icons/dance.png";
import trophy from "../assets/icons/trophy.png";
import balloon from "../assets/icons/balloon.png";
import brush from "../assets/icons/brush.png";
import cake from "../assets/icons/cake.png";
import party from "../assets/icons/party.png";

export const ServicesData = [
  {
    icon: dance,
    title: "Танцувални игри",
    desc: "Раздвижете всяко парти с танцови предизвикателства и музика! Нашите танцови игри са идеални за всички възрасти и обещават непрекъснато вълнуващо и забавно преживяване. ",
  },
  {
    icon: trophy,
    title: "Състезателни игри",
    desc: "Разпалете ентусиазма с разнообразен набор от състезателни игри. Игрите са предназначени да предизвикат радост и отборен дух.",
  },
  {
    icon: balloon,
    title: "Фигури с балони",
    desc: "Моите фигури от балони оживявяат въобръжението на малки и големи. Балоните могат да бъдат всичко от животни до фантастични герои.",
  },
  {
    icon: brush,
    title: "Рисунки върху личица",
    desc: "Перфектни за тематични партита. Преобразете гостите си с артистични рисунки, които радват както децата, така и възрастните. ",
  },
  {
    icon: cake,
    title: "Посрещане на тортата",
    desc: "Нека тортата ви бъде звездата на партито. Тя гарантира незабравим момент когато рожденикът взима първото парче.",
  },
  {
    icon: party,
    title: "Каквото ви хрумне",
    desc: "Независимо дали става въпрос за тематично парти, специално представление или друга дейност, аз съм отдадена в създването на уникално преживяване!",
  },
];
