import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import { motion, useAnimation } from "framer-motion";
import {
    opacityAnimation,
    staggerChildrenAnimation,
} from "../../utils/animations";
import { useInView } from "react-intersection-observer";
import useHandleAnimation from "../../hooks/useHandleAnimation";
import { useLocation } from 'react-router-dom';


const Gallery = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView();
    const animate = useHandleAnimation(inView, controls);
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname])

    useEffect(() => {
        const importAll = (context) => context.keys().map(context);
        const loadedImages = importAll(require.context('../../assets/images', false, /\.(png|jpe?g|svg)$/));
        setImages(loadedImages);
        setIsLoading(false);
    }, []);

    return (
        <Layout>
            {
                isLoading ?
                    (
                        <div className="container flex flex-col items-center justify-center h-screen">
                            <div className="border-t-transparent border-solid animate-spin  rounded-full border-pink-300 border-8 h-64 w-64"></div>
                        </div>
                    ) :
                    (
                        <motion.section
                            variants={staggerChildrenAnimation}
                            animate={controls}
                            className="container my-8 flex flex-col space-y-8"
                            ref={ref}
                            initial="hidden"
                        >
                            <div className="container grid lg:grid-cols-4 gap-5 p-5 pb-10 max-w-7xl mx-auto md:grid-cols-2 sm:grid-cols-1">
                                {images.map((image, index) => (
                                    <div key={index}>
                                        <motion.img
                                            src={image}
                                            className="h-auto max-w-full rounded-lg object-cover object-center "
                                            loading="lazy"
                                            alt={`gallery-${index}`}
                                            style={{ aspectRatio: '1/1' }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </motion.section>
                        // <div className="container grid lg:grid-cols-4 gap-5 p-5 pb-10 max-w-7xl mx-auto md:grid-cols-2 sm:grid-cols-1">
                        //     {images.map((image, index) => (
                        //         <div key={index}>
                        //             <img
                        //                 src={image}
                        //                 className="h-auto max-w-full rounded-lg object-cover object-center "
                        //                 alt={`gallery-${index}`} 
                        //                 style={{ aspectRatio: '1/1' }}/>
                        //         </div>
                        //     ))}
                        // </div>
                    )
            }
        </Layout>

    );
}

export default Gallery;
